










































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'
import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { StepTwoInterface } from '@/store/modules/room.module'

const validations = {}

@Component({
  name: 'StepTwo',
  components: { FormGroup, Field, BaseButton, BaseIcon },
  validations,
})
export default class StepTwo extends Vue {
  @Prop({ required: true })
  value!: StepTwoInterface

  @Watch('value')
  onValueChanged(val: StepTwoInterface): void {
    this.updateModel(val)
  }

  floor: null | number = null
  currentIntegration: null | string = null

  apiToken = ''
  login = ''
  password = ''

  id: null | number = null
  calendarId = ''

  public checkValidity(): boolean {
    this.$v.$touch()

    return !this.$v.$anyError
  }

  get isGoogleIntegrationValidity(): boolean {
    return !!this.calendarId
  }

  get isYandexIntegrationValidity(): boolean {
    return !!this.apiToken && !!this.login && !!this.password
  }

  public updateModel(data: StepTwoInterface): void {
    this.currentIntegration = data?.integrations.current ?? null
    this.id = data?.integrations.gCalendar?.id ?? null
    this.calendarId = data?.integrations.gCalendar?.calendarId ?? ''
    this.apiToken = data?.integrations.yClient.apiToken
    this.login = data?.integrations.yClient.login
    this.password = data?.integrations.yClient.password
  }

  public addIntegration(company: string): void {
    this.currentIntegration = company
    this.update()

    this.$emit('add-integration', company)
  }

  public update(): void {
    this.$emit('input', {
      floor: this.floor,
      integrations: {
        ...this.value.integrations,
        current: this.currentIntegration,
      },
    })
  }

  public mounted(): void {
    this.updateModel(this.value)
  }
}
